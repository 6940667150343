import './style.scss';
import * as THREE from 'three';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import Glide from '@glidejs/glide';
import "../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";
import "../node_modules/@glidejs/glide/src/assets/sass/glide.theme.scss";
import mixitup from 'mixitup';
import mixitupPagination from '../mixitup-pagination.min';


/* ===================== */
/*  Plugin Registration  */
/* ===================== */

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
mixitup.use(mixitupPagination);


/* ================== */
/*  Helper Functions  */
/* ================== */

// this is super dirty but necessary (thanks Safari)
let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
// let isMobile = false;
// if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
// 	|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
// 	isMobile = true;
// }


// let isMobile = false;
// function checkScreen() {
// 	const checkMobile = window.matchMedia('screen and (max-width: 767px)');
// 	checkMobile.addEventListener('change', event => {
// 		if (event.matches) {
// 			isMobile = true;
// 		} else {
// 			isMobile = false;
// 		}
// 	});
// }
// checkScreen();


// intersection observer detection range
// const globalConfig = {
// 	rootMargin: "0px 0px -40% 0px"
// }


// Debounce
const debounce = (callback, wait) => {
	let timeoutId = null;
	return (...args) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => {
			callback.apply(null, args);
		}, wait);
	};
}


window.addEventListener('load', eventWindowLoaded, false);

function eventWindowLoaded() {
	videoControls(); // Hero video mute & play buttons
	shapes3D(); // Three.js shapes
	terrain(); // Red 3D terrain grid
	spotlight(); // Spotlight effect over the 3D terrain
	roadmap(); // Horizontal text, slider/carousel, & custom cursor
	shapes2D(); // SVG animations
	employeeFilter(); //' Meet ThorFi' employee filter
	footerWords(); // Footer word spread effect
	smoothScroll(); // GSAP smoothscroll & navbar
	odinTotalSupply(); // Odin total supply counter
	thorTotalSupply(); // Thor total supply counter
    mediumArticles(); // Get Medium articles
}

function videoControls() { // Hero video mute & play buttons

	// Hooking into Wistia...
	window._wq = window._wq || [];
	_wq.push({ id: "_all", onReady: function(video) {

		// Video vars & listeners
		const videoBtn = document.querySelector('.play-video');
		const videoState = document.querySelector('.video-state');
		let videoPaused = false;
		videoBtn.addEventListener('click', pauseVideo, false);
		videoBtn.addEventListener('mouseenter', circlePlayBtn, false);
		videoBtn.addEventListener('mouseleave', uncirclePlayBtn, false);


		// Audio vars & listeners
		const audioBtn = document.querySelector('.play-audio');
		const audioState = document.querySelector('.audio-state');
		audioBtn.addEventListener('click', muteVideo, false);
		audioBtn.addEventListener('mouseenter', animateAudioBars, false);
		audioBtn.addEventListener('mouseleave', pauseAudioBars, false);


		// Video functions
		function circlePlayBtn() {
			videoState.classList.add('active');
		}
		function uncirclePlayBtn() {
			videoState.classList.remove('active');
		}
		function pauseVideo() {
			if (!videoPaused) {
				video.pause();
				videoState.textContent = "PLAY VIDEO";
			} else {
				video.play();
				videoState.textContent = "PAUSE VIDEO";
			}
			videoPaused = !videoPaused;
		}


		// Audio functions
		function animateAudioBars() {
			// audioBtnTl.play();
			audioState.classList.add('active');
		}
		function pauseAudioBars() {
			// audioBtnTl.pause();
			audioState.classList.remove('active');
		}
		function muteVideo() {
			if (video.isMuted()) {
				video.unmute();
				audioBtnTl.play();
				audioState.textContent = "MUTE AUDIO";
			} else {
				video.mute();
				audioBtnTl.pause();
				audioState.textContent = "PLAY AUDIO";
			}
		}


		// Audio SVG animation
		const audioBtnTl = gsap.timeline({repeat: -1, yoyo: true, repeatRefresh: true, paused: true})
			.to('.audio-bar-left', {duration: 0.2, height: "random(1, 14, 1)"})
			.to('.audio-bar-mid', {duration: 0.2, height: "random(1, 14, 1)"}, 0)
			.to('.audio-bar-right', {duration: 0.2, height: "random(1, 14, 1)"}, 0);

	}});

}

function shapes3D() { // Three.js shapes
	function drawHandles() {
		const canvas = document.querySelector(".canvas-ecosystem");
		const section = document.querySelector(".ecosystem");
		const sizes = {
			width: section.offsetWidth,
			height: section.offsetHeight
		};

		const scene = new THREE.Scene();
		scene.background = null;

		const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
		// camera.position.set(-45, 45, 150);

		const renderer = new THREE.WebGLRenderer({
			canvas: canvas,
			alpha: true,
			antialias: (isSafari || window.devicePixelRatio > 1) ? false : true,
			powerPreference: isSafari ? "high-performance" : "default",
		});
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		// Event listener to handle screen resize
		window.addEventListener("resize", () => {
			// Update sizes
			sizes.width = section.offsetWidth;
			sizes.height = section.offsetHeight;

			// Update camera
			camera.aspect = sizes.width / sizes.height;
			camera.updateProjectionMatrix();

			// Update renderer
			renderer.setSize(sizes.width, sizes.height);
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		});

		const side1Group = new THREE.Group();
		const fullShape = new THREE.Group();

		const lineMaterial = new THREE.LineBasicMaterial({
			color: 0xffffff,
		});

		const shapeMaterial = new THREE.MeshBasicMaterial({
			color: 0x000000,
			side: THREE.DoubleSide,
			polygonOffset: true,
			polygonOffsetUnits: 1,
			polygonOffsetFactor: 1
		});

		const points1 = [];
		const points2 = [];
		const points4 = [];
		points1.push( new THREE.Vector3( 0, -60, 0 ) );
		points1.push( new THREE.Vector3( 13.2, -60, 0 ) );
		points1.push( new THREE.Vector3( 13.2, -28.2, 0 ) );

		points1.push( new THREE.Vector3( 28.2, -13.2, 0 ) );
		points1.push( new THREE.Vector3( 60.1, -13.2, 0 ) );
		points1.push( new THREE.Vector3( 60.1, 0, 0 ) );
		points1.push( new THREE.Vector3( 22.75, 0, 0 ) );
		points1.push( new THREE.Vector3( 0, -22.75, 0 ) );
		points1.push( new THREE.Vector3( 0, -60, 0 ) );

		points2.push( new THREE.Vector3( 0, -60, 0 ) );
		points2.push( new THREE.Vector3( 0, -60, 13.2 ) );
		points4.push( new THREE.Vector3( 60.1, 0, 0 ) );
		points4.push( new THREE.Vector3( 60.1, 0, 13.2 ) );
		const line1Geometry = new THREE.BufferGeometry().setFromPoints( points1 );
		const line2Geometry = new THREE.BufferGeometry().setFromPoints( points2 );
		const line4Geometry = new THREE.BufferGeometry().setFromPoints( points4 );
		// const geometry2 = new THREE.BufferGeometry().setFromPoints( points2 );

		const line1 = new THREE.Line( line1Geometry, lineMaterial );
		const line2 = new THREE.Line( line2Geometry, lineMaterial );
		const line3 = line2.clone();
		line3.position.x = 13.2;
		const line4 = new THREE.Line( line4Geometry, lineMaterial );
		const line5 = line4.clone();
		line5.position.y = -13.2;

		side1Group.add( line1 );
		fullShape.add( line2 );
		fullShape.add( line3 );
		fullShape.add( line4 );
		fullShape.add( line5 );
		// fullShape.add( line3 );
		// scene.add(line2);


		/**
		 * Shape1
		 */
		const shape1 = new THREE.Shape();
		shape1.moveTo( 0, -60 );
		shape1.lineTo( 13.2, -60 );
		shape1.lineTo( 13.2, -28.2 );
		shape1.lineTo( 0, -22.75 );
		shape1.lineTo( 0, -60 );
		const shape1Geometry = new THREE.ShapeGeometry( shape1 );
		const shape1Mesh = new THREE.Mesh( shape1Geometry, shapeMaterial );
		side1Group.add( shape1Mesh );

		/**
		 * Shape2
		 */
		const shape2 = new THREE.Shape();
		shape2.moveTo( 13.2, -28.2 );
		shape2.lineTo( 28.2, -13.2 );
		shape2.lineTo( 22.75, 0 );
		shape2.lineTo( 0, -22.75 );
		shape2.lineTo( 13.2, -28.2 );
		const shape2Geometry = new THREE.ShapeGeometry( shape2 );
		const shape2Mesh = new THREE.Mesh( shape2Geometry, shapeMaterial );
		side1Group.add( shape2Mesh );

		/**
		 * Shape3
		 */
		const shape3Mesh = shape1Mesh.clone();
		shape3Mesh.position.set( 0, 0, 0 );
		shape3Mesh.rotation.y = Math.PI;
		shape3Mesh.rotation.z = Math.PI * -0.5;
		side1Group.add( shape3Mesh );
		// scene.add( side1Group );
		fullShape.add( side1Group );

		/**
		 * Side2
		 */
		const side2Group = side1Group.clone();
		side2Group.position.z = 13.2;
		fullShape.add( side2Group );

		/**
		 * Shape4
		 */
		const shape4 = new THREE.Shape();
		shape4.moveTo( 0, 0 );
		shape4.lineTo( 13.2, 0 );
		shape4.lineTo( 13.2, 13.2 );
		shape4.lineTo( 0, 13.2 );
		shape4.lineTo( 0, 0 );
		const shape4Geometry = new THREE.ShapeGeometry( shape4 );
		const shape4Mesh = new THREE.Mesh( shape4Geometry, shapeMaterial );
		shape4Mesh.position.set( 0, -60, 0);
		shape4Mesh.rotation.x = Math.PI * 0.5
		fullShape.add( shape4Mesh );



		/**
		 * Shape5
		 */
		const shape5Mesh = shape4Mesh.clone();
		shape5Mesh.position.set( 60.1, 0, 0 );
		shape5Mesh.rotation.y = Math.PI * -0.5;
		// shape5Mesh.rotation.z = Math.PI * -0.5;
		fullShape.add( shape5Mesh );


		scene.add( fullShape );

		camera.position.set(0, 0, 750);
		fullShape.position.x = 300;
		fullShape.position.y = 525;
		// fullShape.rotation.x = 0.2;
		// fullShape.rotation.y = 0.2;
		// fullShape.rotation.z = -0.2;


		const secondShape = fullShape.clone();
		secondShape.position.set( 525, 200, 0 );
		secondShape.scale.set( -1, -1, -1 );
		scene.add( secondShape );



		let canvasInView = false;

		function animate() {
			renderer.render(scene, camera);

			if( canvasInView ) {
				requestAnimationFrame(animate.bind(this));
			}
		};
		animate();

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				scrub: 0.75,
				start: "top bottom",
				end: "100%"
			}
		})
		.to(fullShape.position, {y: 500})
		// .to(fullShape.rotation, {x: 5}, 0)
		.to(fullShape.rotation, {y: 0.3}, 0)
		.to(fullShape.rotation, {z: -0.3}, 0);

		const tl2 = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				scrub: 0.75,
				start: "top bottom",
				end: "100%"
			}
		})
		.to(secondShape.position, {y: 175})
		.to(secondShape.rotation, {y: -0.3}, 0)
		.to(secondShape.rotation, {z: -0.3}, 0);

		const observer = new IntersectionObserver((entries, observer)=> {
			entries.forEach( entry => {
				if( entry.isIntersecting ) {
                    requestAnimationFrame(animate.bind(this));
                    canvasInView = true;
                }
                else {
                    canvasInView = false;
                }
			})
		});
        observer.observe(canvas);
	}
	function drawCubePyramid() {
		const canvas = document.querySelector(".canvas-cards");

		const section = document.querySelector(".cards");
		const sizes = {
			width: section.offsetWidth,
			height: section.offsetHeight
		};

		const scene = new THREE.Scene();
		scene.background = null;

		const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
		camera.position.x = -20;
		camera.position.y = -25;
		camera.position.z = 45;

		const renderer = new THREE.WebGLRenderer({
			canvas: canvas,
			alpha: true,
			antialias: (isSafari || window.devicePixelRatio > 1) ? false : true,
			powerPreference: isSafari ? "high-performance" : "default",
		});
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		// Event listener to handle screen resize
		window.addEventListener("resize", () => {
			// Update sizes
			sizes.width = section.offsetWidth;
			sizes.height = section.offsetHeight;

			// Update camera
			camera.aspect = sizes.width / sizes.height;
			camera.updateProjectionMatrix();

			// Update renderer
			renderer.setSize(sizes.width, sizes.height);
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

			// set positioning based on resolution
			if (sizes.width < 767) {
				// cam pos
				camera.position.z = 75;

				// cube pos
				cube.position.x = -15;
				cube.position.y = 30;

				// pyramid pos
				pyramid.position.x = -25;
				pyramid.position.y = 0;
			} else {
				// cam pos
				camera.position.z = 45;

				// cube pos
				cube.position.x = 0;
				cube.position.y = 0;

				// pyramid pos
				pyramid.position.x = -42;
				pyramid.position.y = -20;
			}
		});

		// Cube geometry
		const boxGeometry = new THREE.BoxGeometry(4, 4, 4, 1);
		const boxMaterial = new THREE.MeshPhongMaterial( {
			color: 0x555555,
			polygonOffset: true,
			polygonOffsetFactor: 1, // positive value pushes polygon further away
			polygonOffsetUnits: 1
		} );
		const cube = new THREE.Mesh(boxGeometry, boxMaterial);
		scene.add(cube);

		// Cube wireframe
		const boxWireframeGeometry = new THREE.EdgesGeometry(cube.geometry);
		const boxWireframeMaterial = new THREE.LineBasicMaterial({color: 0xd9d9d9});
		const cubeWireframe = new THREE.LineSegments(boxWireframeGeometry, boxWireframeMaterial);
		cube.add(cubeWireframe);

		// Pyramid geometry
		const coneGeometry = new THREE.ConeGeometry(4, 5.5, 4, 1);
		const coneMaterial = new THREE.MeshPhongMaterial( {
			color: 0x555555,
			polygonOffset: true,
			polygonOffsetFactor: 1, // positive value pushes polygon further away
			polygonOffsetUnits: 1
		} );
		const pyramid = new THREE.Mesh(coneGeometry, coneMaterial);
		scene.add(pyramid);

		// Pyramid wireframe
		const coneWireframeGeometry = new THREE.EdgesGeometry(pyramid.geometry);
		const coneWireframeMaterial = new THREE.LineBasicMaterial({color: 0xd9d9d9});
		const coneWireframe = new THREE.LineSegments(coneWireframeGeometry, coneWireframeMaterial);
		pyramid.add(coneWireframe);

		// set positioning based on resolution
		if (sizes.width < 767) {
			// cam pos
			camera.position.z = 75;

			// cube pos
			cube.position.x = -15;
			cube.position.y = 30;

			// pyramid pos
			pyramid.position.x = -25;
			pyramid.position.y = 0;
		} else {
			// cam pos
			camera.position.z = 45;

			// cube pos
			cube.position.x = 0;
			cube.position.y = 0;

			// pyramid pos
			pyramid.position.x = -42;
			pyramid.position.y = -20;
		}

		// animation tied to user scrolling
		ScrollTrigger.matchMedia({
			"(max-width: 767px)": function() {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						scrub: 0.75,
						start: "top bottom",
						end: "100%"
					}
				})
				.to(cube.position, {y: 0})
				.to(cube.rotation, {x: 5}, 0)
				.to(cube.rotation, {y: 2.5}, 0)
				.to(pyramid.position, {y: -75}, 0)
				.to(pyramid.rotation, {x: 7.25}, 0)
				.to(pyramid.rotation, {y: 7.5}, 0);
			},
			"(min-width: 768px)": function() {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						scrub: 0.75,
						start: "top bottom",
						end: "100%"
					}
				})
				.to(cube.position, {y: -25})
				.to(cube.rotation, {x: 5}, 0)
				.to(cube.rotation, {y: 2.5}, 0)
				.to(pyramid.position, {y: -45}, 0)
				.to(pyramid.rotation, {x: 7.25}, 0)
				.to(pyramid.rotation, {y: 7.5}, 0);
			}
		});

		// render function
		let canvasInView = false;
		function animate() {
			renderer.render(scene, camera);
			if (canvasInView) {
				requestAnimationFrame(animate.bind(this));
			}
		};
		animate();

		// intObs determing if canvas is in viewport
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if(entry.isIntersecting) {
                    requestAnimationFrame(animate.bind(this));
                    canvasInView = true;
                }
                else {
                    canvasInView = false;
                }
			})
		});
        observer.observe(canvas);
	}
	function drawTorus() {
		const section = document.querySelector(".treasury");
		const canvas = document.querySelector(".canvas-treasury");
		const scene = new THREE.Scene();
		const sizes = {
			width: section.offsetWidth,
			height: section.offsetHeight
		};
		const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000);
		const renderer = new THREE.WebGLRenderer({
			canvas: canvas,
			antialias: (isSafari || window.devicePixelRatio > 1) ? false : true,
			powerPreference: isSafari ? "high-performance" : "default",
		});
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		// Event listener to handle screen resize
		window.addEventListener("resize", () => {
			// Update sizes
			sizes.width = section.offsetWidth;
			sizes.height = section.offsetHeight;

			// Update camera
			camera.aspect = sizes.width / sizes.height;
			camera.updateProjectionMatrix();

			// Update renderer
			renderer.setSize(sizes.width, sizes.height);
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

			// fix positioning for mobile
			if (sizes.width < 767) {
				// cam pos
				camera.position.z = 120;

				// torus pos
				torus.position.x = 0;
				torus.position.y = -28;

			} else {
				// cam pos
				camera.position.z = 45;

				// torus pos
				torus.position.x = 17.5;
				torus.position.y = 0;

				// torus rot
				torus.rotation.x = 1.5;
				torus.rotation.y = -2;
			}
		});

		const geometry = new THREE.TorusGeometry(14, 2.5, 20, 40);
		const material = new THREE.MeshPhongMaterial({
			color: 0x555555,
			polygonOffset: true,
			polygonOffsetFactor: 1, // positive value pushes polygon further away
			polygonOffsetUnits: 1
		});
		const torus = new THREE.Mesh(geometry, material);
		scene.add(torus);

		// wireframe
		const wireGeo = new THREE.EdgesGeometry(torus.geometry); // or WireframeGeometry
		const wireMat = new THREE.LineBasicMaterial({color: 0x444444});
		const wireframe = new THREE.LineSegments(wireGeo, wireMat);
		torus.add(wireframe);

		// fix positioning for mobile
		if (sizes.width < 767) {
			// cam pos
			camera.position.z = 120;

			// torus pos
			torus.position.x = 0;
			torus.position.y = -28;

		} else {
			// cam pos
			camera.position.z = 45;

			// torus pos
			torus.position.x = 17.5;
			torus.position.y = 0;

			// torus rot
			torus.rotation.x = 1.5;
			torus.rotation.y = -2;
		}

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				scrub: 0.75,
				start: "top bottom",
				end: "100%"
			}
		})
		.to(torus.rotation, {y: -1.5}, 0);

		let canvasInView = false;
		function animate() {
			torus.rotation.z -= 0.0025;

			renderer.render(scene, camera);
			if (canvasInView) {
				requestAnimationFrame(animate.bind(this));
			}
		};
		animate();

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
                    requestAnimationFrame(animate.bind(this));
                    canvasInView = true;
                } else {
                    canvasInView = false;
                }
			})
		});
        observer.observe(canvas);

	}
	function drawCube() {
		const canvas = document.querySelector(".canvas-story");
		const section = document.querySelector(".story");
		const sizes = {
			width: section.offsetWidth,
			height: section.offsetHeight
		};

		const scene = new THREE.Scene();
		scene.background = null;

		const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
		camera.position.x = 0;
		camera.position.y = 0;
		camera.position.z = 45;

		const renderer = new THREE.WebGLRenderer({
			canvas: canvas,
			alpha: true,
			antialias: (isSafari || window.devicePixelRatio > 1) ? false : true,
			powerPreference: isSafari ? "high-performance" : "default",
		});
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		// Event listener to handle screen resize
		window.addEventListener("resize", () => {
			// Update sizes
			sizes.width = section.offsetWidth;
			sizes.height = section.offsetHeight;

			// Update camera
			camera.aspect = sizes.width / sizes.height;
			camera.updateProjectionMatrix();

			// Update renderer
			renderer.setSize(sizes.width, sizes.height);
			renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		});

		// Cube geometry
		const boxGeometry = new THREE.BoxGeometry(4, 4, 4, 1);
		const boxMaterial = new THREE.MeshPhongMaterial( {
			color: 0x555555,
			polygonOffset: true,
			polygonOffsetFactor: 1, // positive value pushes polygon further away
			polygonOffsetUnits: 1
		} );
		const cube = new THREE.Mesh(boxGeometry, boxMaterial);
		scene.add(cube);

		// Cube wireframe
		const boxWireframeGeometry = new THREE.EdgesGeometry(cube.geometry);
		const boxWireframeMaterial = new THREE.LineBasicMaterial( { color: 0xd9d9d9 } );
		const cubeWireframe = new THREE.LineSegments(boxWireframeGeometry, boxWireframeMaterial);
		cube.add(cubeWireframe);

		// set positioning based on resolution
		if (sizes.width < 767) {
			// cube pos
			cube.position.x = 7.5;
			cube.position.y = 3;
		} else {
			// cube pos
			cube.position.x = -30;
			cube.position.y = 0;
		}

		// animation tied to user scrolling
		ScrollTrigger.matchMedia({
			"(max-width: 767px)": function() {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						scrub: 0.75,
						start: "top bottom",
						end: "100%"
					}
				})
				.to(cube.position, {y: -25})
				.to(cube.rotation, {x: 5}, 0)
				.to(cube.rotation, {y: 2.5}, 0);
			},
			"(min-width: 768px)": function() {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						scrub: 0.75,
						start: "top bottom",
						end: "100%"
					}
				})
				.to(cube.position, {y: -25})
				.to(cube.rotation, {x: 5}, 0)
				.to(cube.rotation, {y: 2.5}, 0);
			}
		});

		// render function
		let canvasInView = false;
		function animate() {
			renderer.render(scene, camera);
			if (canvasInView) {
				requestAnimationFrame(animate.bind(this));
			}
		};
		animate();

		const observer = new IntersectionObserver((entries, observer)=> {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
                    requestAnimationFrame(animate.bind(this));
                    canvasInView = true;
                } else {
                    canvasInView = false;
                }
			})
		});
        observer.observe(canvas);
	}

	drawHandles();
	drawCubePyramid();
	drawTorus();
	drawCube();
}

function terrain() { // Red 3D terrain grid
	const TEXTURE_PATH = "https://i.imgur.com/SaogdYS.png";
	const DISPLACEMENT_PATH = "https://i.imgur.com/JdTRF6L.png";

	// Textures
	const textureLoader = new THREE.TextureLoader();
	const gridTexture = textureLoader.load(TEXTURE_PATH);
	const terrainTexture = textureLoader.load(DISPLACEMENT_PATH);
	const canvas = document.querySelector(".terrain");

	// Scene
	const scene = new THREE.Scene();
	scene.background = new THREE.Color(0xF3523F);

	// Fog
	const fog = new THREE.Fog("#F3523F", 0.1, 4.25);
	scene.fog = fog;

	// Objects
	const geometry = new THREE.PlaneGeometry(1, 1.5, 24, 24);
	const material = new THREE.MeshStandardMaterial({
		map: gridTexture,
		displacementMap: terrainTexture,
		displacementScale: .15,
		// roughness: 0.1,
	});
	material.map.anisotropy = 32;
	// material.map.magFilter = THREE.NearestFilter;
	// material.map.minFilter = THREE.NearestMipmapNearestFilter;

	const plane = new THREE.Mesh(geometry, material);
	plane.rotation.x = -Math.PI * 0.5;
	plane.position.x = 0.03;
	plane.position.y = -0.02;
	plane.position.z = 0;

	const plane2 = new THREE.Mesh(geometry, material);
	plane2.rotation.x = -Math.PI * 0.5;
	plane2.position.x = 0.03;
	plane2.position.y = -0.0201; // lower this to get rid of the blinking line, eg -0.0205
	// plane2.position.z = -1.85; // 0.15 - 2 (the length of the first plane)
	plane2.position.z = 0;

	const plane3 = new THREE.Mesh(geometry, material);
	plane3.rotation.x = -Math.PI * 0.5;
	plane3.position.x = 0.03;
	plane3.position.y = -0.0202; // lower this to get rid of the blinking line, eg -0.021
	plane3.position.z = 0;

	scene.add(plane);
	scene.add(plane2);
	scene.add(plane3);

	// Light
	const hemiLight = new THREE.HemisphereLight(0xFFFFFF, 0xF3523F);
	hemiLight.position.set( 0, 1, 0 );
	scene.add(hemiLight);

	// Sizes
	const sizes = {
	  width: window.innerWidth,
	  height: window.innerHeight - 2,
	};

	// Camera
	const camera = new THREE.PerspectiveCamera(
	  25,
	  sizes.width / sizes.height,
	  0.01,
	  20
	);
	camera.position.x = 0;
	camera.position.y = 0.02;
	camera.position.z = 0.85;

	// Controls
	// const controls = new OrbitControls(camera, canvas);
	// controls.enableDamping = true;

	// Renderer
	const renderer = new THREE.WebGLRenderer({
	  	canvas: canvas,
	  	antialias: (isSafari || window.devicePixelRatio > 1) ? false : true,
		powerPreference: isSafari ? "high-performance" : "default",
	});
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

	// Event listener to handle screen resize
	window.addEventListener("resize", () => {
		// Update sizes
		sizes.width = window.innerWidth;
		sizes.height = window.innerHeight - 2;

		// Update camera
		camera.aspect = sizes.width / sizes.height;
		camera.updateProjectionMatrix();

		// Update renderer
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		// renderer.anisotrophy(2400);
	});

	const clock = new THREE.Clock();
	let canvasInView = true;
	let loadLock = true;
	setTimeout(() => {
		canvasInView = false;
		loadLock = false;
	}, 251);

	// Animate
	const tick = () => {
		const elapsedTime = clock.getElapsedTime();
		// Update controls
		// controls.update();
		plane.position.z = (elapsedTime * 0.05) % 1;
		plane2.position.z = ((elapsedTime * 0.05) % 1) - 1;
		plane3.position.z = ((elapsedTime * 0.05) % 1) - 2;
		// Render
		renderer.render(scene, camera);
		// Call tick again on the next frame
		if (canvasInView) {
			requestAnimationFrame(tick.bind(this));
		}
	};
	tick();

	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				requestAnimationFrame(tick.bind(this));
				canvasInView = true;
			}
			else {
				if (!loadLock) {
					canvasInView = false;
				}
			}
		});
	});
	observer.observe(canvas);
}

function spotlight() { // Spotlight effect over the 3D terrain
	const section = document.querySelector('.landscape');
	let sectionHeight = section.clientHeight;
	let sectionWidth = section.clientWidth;
	section.addEventListener('mousemove', moveCircle);
	window.addEventListener('resize', handleResize);

	const spotlight = document.querySelector('.spotlight');
	let spotlightSize = spotlight.clientWidth;

	function moveCircle(e) {
		// Get the target
		const target = e.currentTarget;

		// Get the bounding rectangle of target
		const rect = target.getBoundingClientRect();

		// Mouse position
		let x = e.clientX - rect.left;
		let y = e.clientY - rect.top;

		// Keeps the spotlight within the section
		if( x < spotlightSize / 2 ) {
			x = spotlightSize / 2
		}
		else if ( x > sectionWidth - spotlightSize / 2 ) {
			x = sectionWidth - spotlightSize / 2
		}
		if( y < spotlightSize / 2 ) {
			y = spotlightSize / 2
		}
		else if ( y > sectionHeight - spotlightSize / 2 ) {
			y = sectionHeight - spotlightSize / 2
		}

		// Delay movement of the spotlight
		gsap.to('.spotlight', {
			duration: 0.75,
			ease: "power2.out",
			left: x,
			top: y
		  });
	}

	function handleResize(e) {
		sectionHeight = section.clientHeight;
		sectionWidth = section.clientWidth;
		spotlightSize = spotlight.clientWidth;
	}
}

function roadmap() { // Horizontal text, slider/carousel, & custom cursor

	// Roadmap section horizontal scrolling text
	const roadmapTl = gsap.timeline({
		scrollTrigger: {
			trigger: ".roadmap",
			scrub: 0.75,
			start: "top bottom",
			end: "100%",
			// markers: true
		}
	})
	.fromTo(".roadmap h1", {x: "25%"}, {x: "-25%"});


	// Roadmap slider init/config
	const roadmapSlider = new Glide('.roadmap-slider', {
		type: 'slider',
		startAt: 0,
		perView: 3,
		breakpoints: {
			768: {
				perView: 1
			},
			1024: {
				perView: 2
			}
		},
		gap: 32
	}).mount();


	// Make top bar of slider clickable to advance
	const cursorText = document.querySelector('.roadmap-cursor-text');
	const sliderBars = document.querySelectorAll('.top-bar');
	sliderBars.forEach(bar => {
		bar.addEventListener('click', advanceToSlide, false);
		bar.addEventListener('mouseenter', changeCursorText, false);
		bar.addEventListener('mouseleave', revertCursorText, false);
	});
	function advanceToSlide(e) {
		roadmapSlider.go('=' + e.currentTarget.dataset.slide) // Figure out a better way than data attributes
	}
	function changeCursorText(e) {
        const tar = e.currentTarget.parentElement;
        if (!tar.classList.contains('glide__slide--active')) {
            cursorText.textContent = 'CLICK';
        }
	}
	function revertCursorText(e) {
		cursorText.innerHTML = '&lt;&nbsp;DRAG&nbsp;&gt;';
	}



	// Custom GSAP cursor for roadmap slider
	function roadmapCursor() {
		const cursor = document.querySelector('.roadmap-cursor');
		const section = document.querySelector('.glide__track');
		let sectionHeight = section.clientHeight;
		let sectionWidth = section.clientWidth;
		section.addEventListener('mousemove', moveCursor);
		section.addEventListener('mouseenter', showCursor);
		section.addEventListener('mouseleave', hideCursor);
		window.addEventListener('resize', handleResize);

		function moveCursor(e) {
			// Get the target
			const target = e.currentTarget;

			// Get the bounding rectangle of target
			const rect = target.getBoundingClientRect();

			// Mouse position
			let x = e.clientX - rect.left;
			let y = e.clientY - rect.top;

			// Delay movement of the spotlight
			gsap.set('.roadmap-cursor', {
				// duration: 0.25,
				// ease: "power2.out",
				left: x,
				top: y
			});
		}
		function showCursor(e) {
			gsap.to(cursor, { scale: 1, duration: 0.15 })
		}
		function hideCursor(e) {
			gsap.to(cursor, { scale: 0, duration: 0.15 })
		}
		function handleResize(e) {
			sectionHeight = section.clientHeight;
			sectionWidth = section.clientWidth;
		}
	}

	roadmapCursor();
}

function shapes2D() { // SVG animations
	const parallaxLines = gsap.timeline({repeat: -1, repeatDelay: 0.3, yoyo: true})
		.to('.parallax-lines span', {duration: 0.3, backgroundColor: '#ffffff', stagger: 0.1})
		.to('.parallax-lines span', {duration: 0.3, backgroundColor: '#4c4c4c', stagger: 0.1}, "-=1.2");


	const parallaxDots = gsap.timeline({repeat: -1, repeatDelay: 1})
		.to('.parallax-dots span', {
			duration: 1,
			scale: 1.5,
			backgroundColor: "#FFFFFF",
			transformOrigin: "center center",
			ease: "power1.inOut",
			stagger: {
				amount: 1,
				ease: "none",
				from: "start"
			}
		})
		.to('.parallax-dots span', {
			duration: 1,
			scale: 1,
			backgroundColor: "#EDEDED",
			transformOrigin: "center center",
			ease: "power1.inOut",
			stagger: {
				amount: 1,
				ease: "none",
				from: "end"
			}
		});
}

function employeeFilter() { // 'Meet ThorFi' employee filter
	const employees = mixitup('.team-container', {
		selectors: {
			target: '.team-member',
			pageList: '.team-pages'
		},
		animation: {
			// enable: false,
			effects: 'fade',
			// effectsIn: 'fade translateY(0%)',
			// effectsOut: 'fade',
			duration: 300,
			easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
			applyPerspective: false,
		},
		pagination: {
			limit: 6,
			hidePageListIfSinglePage: true
		},
		templates: {
			pager: '<button type="button" class="${classNames}" data-page="${pageNumber}">0${pageNumber}</button>',
			pagerPrev: '<button type="button" class="${classNames}" data-page="prev"></svg><svg width="42" height="13" viewBox="0 0 42 13" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_569_1234)"><path d="M41.4451 6.9043L0.207031 6.9043" stroke="#BCBEC0" stroke-width="2" stroke-miterlimit="10"/><path d="M5.95581 12.9219L0.109375 6.88585" stroke="#BCBEC0" stroke-width="2" stroke-miterlimit="10"/><path d="M0.078125 6.82422L6.11415 0.977785" stroke="#BCBEC0" stroke-width="2" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_569_1234"><rect width="12.1007" height="41.4453" fill="white" transform="translate(0 13) rotate(-90)"/></clipPath></defs></svg></button>',
			pagerNext: '<button type="button" class="${classNames}" data-page="next"><svg width="42" height="13" viewBox="0 0 42 13" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_569_1230)"><path d="M0.000196978 6.0957L41.2383 6.0957" stroke="#BCBEC0" stroke-width="2" stroke-miterlimit="10"/><path d="M35.4895 0.0781247L41.3359 6.11415" stroke="#BCBEC0" stroke-width="2" stroke-miterlimit="10"/><path d="M41.3672 6.17578L35.3312 12.0222" stroke="#BCBEC0" stroke-width="2" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_569_1230"><rect width="12.1007" height="41.4453" fill="white" transform="translate(41.4453) rotate(90)"/></clipPath></defs></svg></button>'
		}
	});

	const teamContainer = document.querySelector('.team-container');
	teamContainer.addEventListener('mixEnd', refreshScrollTrigger, false);

	function refreshScrollTrigger() {
		ScrollTrigger.refresh();
	}

	const expandersOpen = document.querySelectorAll('.expander-open');
	expandersOpen.forEach(expander => {
		expander.addEventListener('click', openMemberBio, false);
	});

	function openMemberBio(e) {
		const openBio = document.querySelector('.reveal-content.active');
		if( openBio !== null ) {
			openBio.classList.remove('active');
		}

		const tar = e.currentTarget.parentElement.parentElement.querySelector('.reveal-content');
		tar.classList.add('active');
	}

	const expandersClose = document.querySelectorAll('.expander-close');
	expandersClose.forEach(expander => {
		expander.addEventListener('click', closeMemberBio, false);
	});

	function closeMemberBio(e) {
		const tar = e.currentTarget.parentElement.parentElement;
		tar.classList.remove('active');
	}

	function teamMemberBioToggle(e) {
		const tar = e.currentTarget.parentElement.parentElement.querySelector('.reveal-content');
		if (tar.classList.contains('active')) {
			tar.classList.remove('active');
		} else {
			// expanders.forEach(expander => {
			// 	expander.classList.remove('active');
			// });
			tar.classList.add('active');
		}
	}
}

function footerWords() { // Footer word spread effect
	const spreadEffects = document.querySelectorAll('.spread-effect');

	spreadEffects.forEach(words => {
		words.addEventListener('mouseover', spreadWords, false);
		words.addEventListener('mouseout', unspreadWords, false);
	});

	function spreadWords(e) {
		const tar = e.currentTarget;
		const tl = new gsap.timeline()
			.to(tar.querySelector('.se-topmid'), {duration: 0.5, y: -10},)
			.to(tar.querySelector('.se-botmid'), {duration: 0.5, y: 10}, "-=0.5")
			.to(tar.querySelector('.se-top'), {duration: 0.5, y: -20}, "-=0.5")
			.to(tar.querySelector('.se-bot'), {duration: 0.5, y: 20}, "-=0.5");
	}

	function unspreadWords() {
		gsap.to('.se-topmid, .se-botmid, .se-top, .se-bot', {duration: 0.5, y: 0});
	}
}

function smoothScroll() { // GSAP smoothscroll & navbar
	let smoother = ScrollSmoother.create({
		smooth: 1.75,            // how long (in seconds) it takes to "catch up" to the native scroll position
		// effects: true,        // looks for data-speed and data-lag attributes on elements
		smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
		normalizeScroll: false,
		ignoreMobileResize: true,
		autoResize: false
	});

	function navbar() { // Navbar functionality & animations
		const btnLinks = document.querySelectorAll('.btn-link');
		btnLinks.forEach(link => {
			link.addEventListener('click', e => {
				const url = '#' + e.currentTarget.dataset.link;
				if (url) {
					e.preventDefault();
					smoother.scrollTo(url, true, "top 103px");
				}
			});
		});

		const navLinks = document.querySelectorAll('.nav-link');
		navLinks.forEach(link => {
			link.addEventListener('click', e => {
				const url = e.currentTarget.href;
				const index = url.indexOf('#');
				if (index !== -1) {
					const hash = url.substring(index);
					e.preventDefault();
					smoother.scrollTo(hash, true, "top 103px");
				}
			});
		});

		let links = gsap.utils.toArray("nav a.nav-link");
		links.forEach(a => {
			let element = document.querySelector(a.getAttribute("href")),
				linkST = ScrollTrigger.create({
						trigger: element,
						start: "top top"
					});
			ScrollTrigger.create({
				trigger: element,
				start: "top center",
				end: "bottom center",
				onToggle: self => self.isActive && setActive(a)
			});
		});

		function setActive(link) {
			links.forEach(el => el.classList.remove("active"));
			link.classList.add("active");
		}

		let linksm = gsap.utils.toArray("nav a.nav-link-mobile");
		linksm.forEach(a => {
			let element = document.querySelector(a.getAttribute("href")),
				linkST = ScrollTrigger.create({
						trigger: element,
						start: "top top"
					});
			ScrollTrigger.create({
				trigger: element,
				start: "top center",
				end: "bottom center",
				onToggle: self => self.isActive && setActivem(a)
			});
		});

		function setActivem(link) {
			linksm.forEach(el => el.classList.remove("active"));
			link.classList.add("active");
		}


		let mobileMenuOpen = false;
		const mobileMenu = document.querySelectorAll('.mobile-menu, .mobile-close');
			mobileMenu.forEach(el => {
			el.addEventListener('click', e => {
				if (mobileMenuOpen) {
					gsap.to(".mobile-nav", 0.3, {autoAlpha: 0});
					gsap.to(".mobile-menu", 0.3, {autoAlpha: 1});
				} else {
					gsap.to(".mobile-nav", 0.3, {autoAlpha: 1});
					gsap.to(".mobile-menu", 0.3, {autoAlpha: 0});
				}
				mobileMenuOpen = !mobileMenuOpen;
			});
		});
	}
	navbar();
}

function disclaimerModal() {
	// Get the modal
	var disclaimerModal = document.getElementById('Disclaimer');

	// Get the button that opens the modal
	var modalBtn = document.getElementById("Disclaimer-btn");

	// Get the <span> element that closes the modal
	var modalClose = document.getElementsByClassName("modal-close")[0];

	// When the user clicks the button, open the modal
	modalBtn.onclick = function(e) {
		e.preventDefault();
		disclaimerModal.style.display = "block";
	}

	// When the user clicks on <span> (x), close the modal
	modalClose.onclick = function() {
		disclaimerModal.style.display = "none";
	}

	// When the user clicks anywhere outside of the modal, close it
	window.onclick = function(event) {
		if (event.target == disclaimerModal) {
			disclaimerModal.style.display = "none";
		}
	}

	if(window.location.href.indexOf('#Disclaimer') != -1) {
		disclaimerModal.style.display = "block";
	}
}

function odinTotalSupply()
{
    const elOdinTotalSupply = document.querySelector(".odin-total-supply");
    //elOdinTotalSupply.innerHTML = "Merc";

    const odinContract = "0x7325e3564B89968D102B3261189EA44c0f5f1a8e";
    const url = "https://avalanche.rpc.thirdweb.com";

    const jsonParams = {
        "jsonrpc":"2.0",
        "method":"eth_call",
        "params":[
            {
                "to":odinContract,
                "data":"0x18160ddd0000000000000000000000000000000000000000000000000000000000000000"
            },
            "latest"
        ],
        "id":"1243.1324"

    };
    // Define your Fetch options
    let options = {
        method: 'POST', // or 'GET'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonParams), // put the JSON string into the body of the request
    };

    // Use the Fetch API to make your request
    fetch(url, options)
        .then(response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json(); // This returns a promise
        })
        .then(json => {
            elOdinTotalSupply.innerHTML = (parseInt( json.result.slice(-25), 16 ) / 1000).toFixed(0) ;
        })
        .catch(function () {
           console.log("Error: could not get odin total supply");
        });

}

function thorTotalSupply()
{
    const elThorTotalSupply = document.querySelector(".thor-total-supply");
    //elThorTotalSupply.innerHTML = "Rules";

    const thorContract = "0x825189515d0A7756436F0eFb6e4bE5A5aF87e21D";
    const url = "https://avalanche.rpc.thirdweb.com";

    const jsonParams = {
        "jsonrpc":"2.0",
        "method":"eth_call",
        "params":[
            {
                "to":thorContract,
                "data":"0x18160ddd0000000000000000000000000000000000000000000000000000000000000000"
            },
            "latest"
        ],
        "id":"5454.6567"

    };
    // Define your Fetch options
    let options = {
        method: 'POST', // or 'GET'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonParams), // put the JSON string into the body of the request
    };

    // Use the Fetch API to make your request
    fetch(url, options)
        .then(response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json(); // This returns a promise
        })
        .then(json => {
            elThorTotalSupply.innerHTML = (parseInt( json.result.slice(-25), 16 ) / 1000).toFixed(0) ;
        })
        .catch(function () {
           console.log("Error: could not get thor total supply");
        });
}

function mediumArticles()
{
    const elArticles = document.querySelector(".articles");

    const url = "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40thorfiofficial";

    let options = {
        method: 'get', // or 'GET'
    };

    fetch (url, options)
        .then(response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json(); // This returns a promise
        })
        .then(json => {
            json.items.slice(0,6).forEach(item => {
                const dateObject = new Date(item.pubDate);

                let day = String(dateObject.getDate()).padStart(2, '0');
                let month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0 based, hence we add 1
                let year = dateObject.getFullYear();

                let formattedDate = month + '/' + day + '/' + year;

                const articleItem = document.createElement('div');
                articleItem.classList.add('article');
                articleItem.classList.add('redline');

                const articlePublishDate = document.createElement('p');
                articlePublishDate.classList.add('ti');

                const publishDateContent = document.createTextNode(formattedDate);
                articlePublishDate.appendChild(publishDateContent);

                const articleLink = document.createElement('a');
                articleLink.href = item.link;

                const linkContent = document.createTextNode(item.title.replace( "&amp;", "&" ));
                articleLink.appendChild(linkContent);

                articleItem.appendChild(articlePublishDate);
                articleItem.appendChild(articleLink);

                elArticles.appendChild(articleItem);
            });
        })
        .catch(function () {
            console.log("Error: could not get articles");
        });
}






// temporary work here

// const socialIcons = document.querySelectorAll('.social-icon');
// socialIcons.forEach(icon => {
// 	icon.addEventListener('mouseenter', colorizeIcon, false);
// 	icon.addEventListener('mouseleave', decolorizeIcon, false);
// });
// function colorizeIcon(e) {
// 	const ring = e.currentTarget.querySelector('.fg');
// 	const icon = e.currentTarget.querySelector('path');
// 	gsap.timeline()
// 		.to(ring, {duration: 0.3, strokeDashoffset: 0})
// 		.to(icon, {duration: 0.3, fill: '#F3523F'})
// }
// function decolorizeIcon(e) {
// 	const ring = e.currentTarget.querySelector('.fg');
// 	const icon = e.currentTarget.querySelector('path');
// 	gsap.timeline()
// 		.to(icon, {duration: 0.3, fill: '#F8F8F8'})
// 		.to(ring, {duration: 0.3, strokeDashoffset: 100})
// }
